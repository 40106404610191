import { useNavigationQuery } from '@contentfulTypes'
import { JsonLdScript } from './JsonLdScript'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useRouter } from 'next/router'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { DEFAULT_LOCALE } from '~/config/constants'
import { getStoreCode } from '~/lib/getStoreCode'

const phonesMap: Record<string, string> = {
  benuta_de: '+49 228 5341 4000',
  benuta_at: '+ 49 228 5341 4000',
  benuta_chde: '+49 228 5341 4000',
  benuta_chfr: '+49 228 5341 4000',
  benuta_chit: '+49 228 5341 4000',
  benuta_fr: '0184 889 908',
  benuta_it: '02 947 525 95',
  benuta_es: '91 187 65 65',
  benuta_co_uk: '+44 161 8508538',
  benuta_eu: '+49 228 5341 4005',
  benuta_nl: '+31 97 010281021',
  benuta_pl: '+48 22 204 37 57',
  benuta_se: '+46 79 0083409',
  benuta_dk: '+49 228 9696 895',
  benuta_cz: '+49 228 5341 4005',
  benuta_pt: '+49 228 5341 4005',
  benuta_fi: '+49 228 5341 4005',
  benuta_no: '+49 228 5341 4005',
}

const CorportationJsonLd: React.FC = () => {
  const baseUrl = `https://www.${process.env.NEXT_PUBLIC_DOMAIN_NAME}/`
  const router = useRouter()
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const storeCode = getStoreCode({ locale: finalLocale }) || 'benuta_de'

  const { data: navigationData } = useNavigationQuery(contentfulClient, {
    preview,
    locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
  })

  return (
    <JsonLdScript
      id="corporation-json-ld"
      content={JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Corporation',
        url: baseUrl,
        logo: `${baseUrl}media/logo/default/logo.png`,
        legalName: 'benuta GmbH',
        sameAs: navigationData?.pageCollection?.items?.[0]?.socialFooterIconsCollection?.items
          ?.filter((i) => !!i?.link)
          .map((i) => i?.link),
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: phonesMap?.[storeCode] || '+49 228 5341 4000',
            email: 'support@benuta.com',
            contactType: 'customer service',
            hoursAvailable: [
              {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                opens: '09:00',
                closes: '18:00',
              },
            ],
          },
        ],
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Bonn',
          addressRegion: 'North Rhine-Westphalia',
          postalCode: '53119',
          streetAddress: 'Hohe Str. 87',
          addressCountry: {
            '@type': 'Country',
            name: 'DE',
          },
        },
      })}
    />
  )
}

export { CorportationJsonLd }

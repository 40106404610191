import { useCategoryUrlsQuery, useProductUrlsQuery } from '@magentoTypes'
import { useLocalizedSlugsQuery } from '@contentfulTypes'
import { useRouter } from 'next/router'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createMagentoClient } from '~/graphql/magentoClient'
import { createGraphQLClient } from '~/graphql/contentfulClient'
import { i18n } from '~/next-i18next.config'
import { usePreviewMode } from './usePreviewMode'
import { useQueryClient } from '@tanstack/react-query'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

interface LanguageAlternate {
  hrefLang: string
  href: string
  locale: string
}

type Props = {
  identifier: any
  type: PageType
}

type ReturnType = {
  canonical?: string
  languageAlternates?: Array<LanguageAlternate>
}

const getURLByDomain = (urls: string[], domain: string) => {
  const url = urls.find((v) => v.includes(domain))
  return url ? new URL(url)?.pathname : null
}

const getSlugsList = (slugs: any, type: PageType) => {
  const slugsList = {} as any

  if (slugs) {
    if (type === PageType.CTF_CMS_PAGE) {
      if (slugs?.deDE) slugsList['de-DE'] = `/${slugs?.deDE}`
      if (slugs?.deAT) slugsList['de-AT'] = `/${slugs?.deAT}`
      if (slugs?.frFR) slugsList['fr-FR'] = `/${slugs?.frFR}`
      if (slugs?.esES) slugsList['es-ES'] = `/${slugs?.esES}`
      if (slugs?.itIT) slugsList['it-IT'] = `/${slugs?.itIT}`
      if (slugs?.enGB) slugsList['en-GB'] = `/${slugs?.enGB}`
      if (slugs?.enEU) slugsList['en-EU'] = `/${slugs?.enEU}`
      if (slugs?.deCH) slugsList['de-CH'] = `/${slugs?.deCH}`
      if (slugs?.frCH) slugsList['fr-CH'] = `/${slugs?.frCH}`
      if (slugs?.itCH) slugsList['it-CH'] = `/${slugs?.itCH}`
      if (slugs?.nlNL) slugsList['nl-NL'] = `/${slugs?.nlNL}`
      if (slugs?.plPL) slugsList['pl-PL'] = `/${slugs?.plPL}`
      if (slugs?.svSE) slugsList['sv-SE'] = `/${slugs?.svSE}`
      if (slugs?.daDK) slugsList['da-DK'] = `/${slugs?.daDK}`
      if (slugs?.csCZ) slugsList['cs-CZ'] = `/${slugs?.csCZ}`
      if (slugs?.ptPT) slugsList['pt-PT'] = `/${slugs?.ptPT}`
      if (slugs?.fiFI) slugsList['fi-FI'] = `/${slugs?.fiFI}`
      if (slugs?.nnNO) slugsList['nn-NO'] = `/${slugs?.nnNO}`
      //  if (slugs?.deDE) slugsList['x-default'] = `/${slugs?.deDE}`
    } else {
      const deDE = getURLByDomain(slugs, 'benuta.de')
      if (deDE) slugsList['de-DE'] = deDE
      const deAT = getURLByDomain(slugs, 'benuta.at')
      if (deAT) slugsList['de-AT'] = deAT
      const frFR = getURLByDomain(slugs, 'benuta.fr')
      if (frFR) slugsList['fr-FR'] = frFR
      const esES = getURLByDomain(slugs, 'benuta.es')
      if (esES) slugsList['es-ES'] = esES
      const itIT = getURLByDomain(slugs, 'benuta.it')
      if (itIT) slugsList['it-IT'] = itIT
      const enGB = getURLByDomain(slugs, 'benuta.co.uk')
      if (enGB) slugsList['en-GB'] = enGB
      const enEU = getURLByDomain(slugs, 'benuta.eu')
      if (enEU) slugsList['en-EU'] = enEU
      const deCH = getURLByDomain(slugs, 'benuta.ch/de')
      if (deCH) slugsList['de-CH'] = deCH
      const frCH = getURLByDomain(slugs, 'benuta.ch/fr')
      if (frCH) slugsList['fr-CH'] = frCH
      const itCH = getURLByDomain(slugs, 'benuta.ch/it')
      if (itCH) slugsList['it-CH'] = itCH
      const nlNL = getURLByDomain(slugs, 'benuta.nl')
      if (nlNL) slugsList['nl-NL'] = nlNL
      const plPL = getURLByDomain(slugs, 'benuta.pl')
      if (plPL) slugsList['pl-PL'] = plPL
      const svSE = getURLByDomain(slugs, 'benuta.se')
      if (svSE) slugsList['sv-SE'] = svSE
      const daDK = getURLByDomain(slugs, 'benuta.dk')
      if (daDK) slugsList['da-DK'] = daDK
      const csCZ = getURLByDomain(slugs, 'benuta.cz')
      if (csCZ) slugsList['cs-CZ'] = csCZ
      const ptPT = getURLByDomain(slugs, 'benuta.pt')
      if (ptPT) slugsList['pt-PT'] = ptPT
      const fiFI = getURLByDomain(slugs, 'benuta.fi')
      if (fiFI) slugsList['fi-FI'] = fiFI
      const nnNO = getURLByDomain(slugs, 'benuta.no')
      if (nnNO) slugsList['nn-NO'] = nnNO
      //if (deDE) slugsList['x-default'] = deDE
    }
  }

  return slugsList
}

export enum PageType {
  MAGENTO_PRODUCT_PAGE,
  MAGENTO_CATEGORY_PAGE,
  CTF_CMS_PAGE,
  DISABLED_PAGE,
}

export const useSEO = ({ identifier, type }: Props): ReturnType => {
  const alternativeHrefs = [] as Array<LanguageAlternate>
  const preview = usePreviewMode()
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const contentfulClient = createGraphQLClient({ preview })

  const { data: magentoCategoryPageData } = useCategoryUrlsQuery(
    magentoClient,
    {
      identifier: identifier as number,
    },
    {
      enabled: type == PageType.MAGENTO_CATEGORY_PAGE && !!identifier,
    },
  )
  const { data: magentoProductPageData } = useProductUrlsQuery(
    magentoClient,
    {
      identifier: identifier as number,
    },
    {
      enabled: type == PageType.MAGENTO_PRODUCT_PAGE && !!identifier,
    },
  )
  const { data: ctfPrimaryPageData } = useLocalizedSlugsQuery(
    contentfulClient,
    {
      slug: identifier as string,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    {
      enabled: type == PageType.CTF_CMS_PAGE && !!identifier,
    },
  )
  const { data: ctfRelatedPageData } = useLocalizedSlugsQuery(
    contentfulClient,
    {
      slug: ctfPrimaryPageData?.pageCollection?.slugs?.[0]?.relatedPage?.slug as string,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    {
      enabled:
        type == PageType.CTF_CMS_PAGE &&
        !!ctfPrimaryPageData?.pageCollection?.slugs?.[0]?.relatedPage?.slug,
    },
  )

  let slugsList = {} as any
  if (type == PageType.MAGENTO_CATEGORY_PAGE)
    slugsList = getSlugsList(magentoCategoryPageData?.categoryUrls, PageType.MAGENTO_CATEGORY_PAGE)
  else if (type == PageType.MAGENTO_PRODUCT_PAGE)
    slugsList = getSlugsList(magentoProductPageData?.productUrls, PageType.MAGENTO_PRODUCT_PAGE)
  else
    slugsList = getSlugsList(
      {
        ...ctfPrimaryPageData?.pageCollection?.slugs?.[0],
        ...ctfRelatedPageData?.pageCollection?.slugs?.[0],
      },
      PageType.CTF_CMS_PAGE,
    )

  for (const locale in slugsList) {
    const localizedSlug = slugsList[locale]
    let correctedLocale = locale

    if (locale == 'de-CH' || locale == 'fr-CH' || locale == 'it-CH') correctedLocale = 'de-CH'
    /*else if (locale == 'x-default') correctedLocale = 'de-DE'*/

    const storeDomain = i18n.domains.find((v) => v?.defaultLocale == correctedLocale)?.domain
    alternativeHrefs.push({
      hrefLang: locale == 'en-EU' ? 'en' : locale,
      href:
        locale == 'de-CH' || locale == 'fr-CH' || locale == 'it-CH'
          ? `https://${storeDomain}/${locale.split('-')?.[0]}${
              identifier != 'home' ? localizedSlug.replace(/^(\/de\/|\/fr\/|\/it\/)/, '/') : ''
            }`
          : `https://${storeDomain}${identifier != 'home' ? localizedSlug : ''}`,
      locale,
    })
  }

  return type != PageType.DISABLED_PAGE
    ? {
        canonical: alternativeHrefs.find((v: any) => v?.locale == finalLocale)?.href,
        languageAlternates: alternativeHrefs,
      }
    : {
        // Return nothing...
      }
}

import { JsonLdScript } from './JsonLdScript'

const WebsisteJsonLd: React.FC = () => {
  const baseUrl = `https://www.${process.env.NEXT_PUBLIC_DOMAIN_NAME}/`

  return (
    <JsonLdScript
      id="website-json-ld"
      content={JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: baseUrl,
        potentialAction: {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${baseUrl}search?q={search_term_string}`,
          },
          'query-input': {
            '@type': 'PropertyValueSpecification',
            valueRequired: 'http://schema.org/True',
            valueName: 'search_term_string',
          },
        },
      })}
    />
  )
}

export { WebsisteJsonLd }

type Props = {
  id: string
  content: string
}

const JsonLdScript: React.FC<Props> = ({ id, content }) => {
  return (
    <script
      id={id}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}

export { JsonLdScript }
